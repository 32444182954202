<template>
    <div class="h-90 mr-2">
        <div class="pb-10 h-100">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center ml-2">
                <div class="breadcrumbactive">
                    <div >
                        <span>Manage Organization </span>
                        <!-- <div>Create New</div> -->
                    </div>
                </div>
            </div>
            <div class="create-company-container w-100 h-100 ml-2 ">
                <!-- <div class="create-company-header">
                    <span class="fs-15 fw-600">Select Creation Type </span>
                </div> -->
                <div class="mt-9">
                    <h1 class="text-center fw-600 fs-17 mb-9 pt-4 " >Select Creation Type</h1>
                    <div class="row no-gutters align-items-center justify-content-center">
                        <div class="select-type-box d-flex align-items-center justify-content-center flex-column pointer" :class="{'select-type-box-active':organisation}"  style="width:312px">
                            <img width="50px" height="50px" src="/static/images/organization-icon.svg" alt="Organization" />
                            <p class="fs-15 fw-600 mb-3 pt-8" >Organization
                                <!-- <el-tooltip class="item" effect="dark"
                                    placement="top-start">
                                    <template slot="content">
                                        <span>Organization means parent organization <br> or parent company which is the owner of <br> multiple companies</span>
                                    </template>
                                    <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                </el-tooltip> -->
                            </p>
                            <div class=" text-center fw-500 fs-15">
                                Here, Organization stands for <br> Parent Organization/Company <br> which is the owner of multiple <br> Companies
                            </div>
                            <button class="btn btn-select-custom fs-15 fw-500 mt-5" @click="goToCompany('organisation')">Select</button>
                        </div>
                        <div class="select-type-box ml-20 d-flex align-items-center justify-content-center flex-column pointer" :class="{'select-type-box-active':standalone}"  style="width:312px;height: 309px;">
                            <img width="50px" height="50px" src="/static/images/company-icon.svg" alt="Standalone" />
                            <p class="fs-15 mb-3 fw-600 pt-8">Standalone Company</p>
                            <div class="text-center fs-15 fw-500" @click="goToCompany('standalone')">Here, Standalone Company <br> stands  for an Individual <br> Company.</div>
                            <button class="btn btn-select-custom fs-15 fw-500 mt-5">Select</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            organisation: true,
            standalone: false,
        }
    },
    methods: {
        goToCompany(type) {
            if(type == 'organisation') {
                this.organisation = true;
                this.standalone = false;
                this.$router.push('/organization/create');
            } else if(type == 'standalone') {
                this.organisation = false;
                this.standalone = true;
                this.$router.push('/company/createcompany/standalonecompany');
            }
        }
    }, 
    mounted() {
        if(this.loggedInUser.has_organization_or_standalone) {
            if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                if(this.loggedInUser.user_has == 'standalone') {
                    this.$router.push('/company/listview');
                } else if(this.loggedInUser.user_has == 'organization') {
                    this.$router.push('/organization/listview');
                } else if(this.loggedInUser.user_has == 'companies') {
                    this.$router.push('/organization/listview');
                }
            } else if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
                if(this.loggedInUser.user_has == 'company') {
                    this.$router.push('/company/listview');
                }
            }
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        }
    },
}
</script>

<style scoped>

.create-company-container {
    background-color: #fff;
    padding: 0 0 40px; 
    border-radius: 0 6px 0 0;
}
.create-company-header {
    padding: 9.9px 15px;
    background-color: #00448b;
    border-radius: 0 6px 0 0;
}
.select-type-box {
    padding: 31.5px 9.5px 12.5px 9px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 #eaeaea;
    background-color: #fff;
    border: solid 1px transparent;
    transition: border .3s;
}
.select-type-box-active {
    border: solid 1px #00448b;
}
.btn-select-custom{
 background: #6b84e4 !important;
 color: white;
}
</style>